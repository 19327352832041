import React from "react";

const SvgComponent = props => (
  <svg viewBox="0 0 13.229 13.229" height={50} width={50} {...props}>
    <g fill="#fff" stroke="#727273" strokeWidth={1.379} strokeLinejoin="round">
      <path
        d="M4.125 10.778a.3.3 0 0 1-.097-.053c-.095-.086-.095-.089-.095-1.037 0-.858.006-.962.064-1.195.03-.12.156-.392.236-.51.088-.128.302-.351.43-.446.13-.098.327-.196.532-.268l.159-.055.934-.011a24.236 24.236 0 0 1 1.093.003c.224.02.389.066.596.166.477.231.807.645.958 1.2.038.141.062 1.891.027 2.02a.354.354 0 0 1-.082.138l-.061.058-2.319.004c-1.275.002-2.344-.004-2.375-.014zM6.238 7.034a1.498 1.498 0 0 1-1.06-.922 1.303 1.303 0 0 1-.092-.506c0-.38.134-.717.394-.992.21-.221.418-.346.703-.423.235-.063.515-.067.72-.01.218.059.229.063.346.125.668.354.967 1.13.701 1.82-.15.39-.512.737-.901.865a1.769 1.769 0 0 1-.811.043zM.585 9.07c-.068-.028-.126-.106-.148-.2-.026-.114-.027-1.38 0-1.697a1.754 1.754 0 0 1 .547-1.181c.177-.17.32-.265.53-.354.316-.133.31-.132 1.505-.14l1.084-.008.027.174c.044.292.157.546.347.786.094.118.29.292.41.363l.07.041-.19.012a1.915 1.915 0 0 0-1.559.98c-.159.289-.209.492-.223.9l-.011.343H1.799C1.153 9.086.607 9.078.585 9.07zM2.78 5.365a1.447 1.447 0 0 1-.737-.373c-.342-.32-.521-.803-.458-1.238.05-.352.181-.61.44-.866.189-.189.383-.301.646-.376.195-.055.58-.055.775 0 .548.155.95.587 1.066 1.147.152.73-.32 1.475-1.066 1.68a1.784 1.784 0 0 1-.666.026zM9.823 9.113c-.04-.006-.043-.019-.054-.347-.008-.251-.022-.377-.05-.471a1.892 1.892 0 0 0-.708-1.04 1.833 1.833 0 0 0-.796-.35c-.232-.043-.225-.033-.087-.125.37-.247.625-.653.697-1.106l.025-.161h1.024c.563 0 1.097.009 1.187.019.583.067 1.076.49 1.25 1.073.054.184.067.468.067 1.476v1.028h-.045c-.859.006-2.48.009-2.51.004zM9.706 5.392c-.283-.06-.417-.114-.606-.244-.277-.19-.5-.502-.588-.82a1.792 1.792 0 0 1-.029-.629c.113-.568.475-.97 1.044-1.155.213-.07.594-.074.812-.01.37.11.639.303.835.603.176.268.249.5.25.796a1.456 1.456 0 0 1-.93 1.373c-.23.093-.58.131-.788.086z"
        strokeWidth={0.39614533}
      />
    </g>
  </svg>
);

export default SvgComponent;

