import React from "react";

const SvgProteinIcon = props => (
  <svg viewBox="0 0 13.229 13.229" height="1em" width="1em" {...props}>
    <defs>
      <clipPath id="ProteinIcon_svg__a" clipPathUnits="userSpaceOnUse">
        <path d="M0 53.828h16.234V0H0z" />
      </clipPath>
    </defs>
    <g
      clipPath="url(#ProteinIcon_svg__a)"
      transform="matrix(.24433 .16118 .16118 -.24433 .39 12.109)"
    >
      <path
        d="M1.202 17.605l13.853-6.253v8.43L2.396 27.186zM1.202 34.046l13.853-6.253v8.43L2.396 43.627z"
        fill="none"
        stroke="#7c7d80"
        strokeWidth={2.359}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <path
        d="M15.055 52.648c0-.712-1.425-1.741-1.425-1.741s-7.758-4.908-10.29-6.571c-2.533-1.662-2.138-2.928-2.138-2.928v-7.362s0 .475.95 1.108c.95.634 11.795 7.758 11.795 7.758s1.108 1.029 1.108 1.662zM15.055 19.782c0-.712-1.425-1.741-1.425-1.741S5.872 13.133 3.34 11.47C.807 9.808 1.202 8.542 1.202 8.542V1.18s0 .475.95 1.108c.95.634 11.795 7.758 11.795 7.758s1.108 1.029 1.108 1.662zM15.055 36.207c0-.712-1.425-1.742-1.425-1.742s-7.758-4.907-10.29-6.57c-2.533-1.662-2.138-2.928-2.138-2.928v-7.362s0 .475.95 1.108c.95.634 11.795 7.758 11.795 7.758s1.108 1.028 1.108 1.662z"
        fill="none"
        stroke="#7c7d80"
        strokeWidth={2.359}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
      <path
        d="M1.191 17.605l13.853-6.253v8.43L2.385 27.186zM1.191 34.046l13.853-6.253v8.43L2.385 43.627z"
        fill="#7c7d80"
      />
      <path
        d="M15.055 52.648c0-.712-1.425-1.741-1.425-1.741s-7.758-4.908-10.29-6.571c-2.533-1.662-2.138-2.928-2.138-2.928v-7.362s0 .475.95 1.108c.95.634 11.795 7.758 11.795 7.758s1.108 1.029 1.108 1.662z"
        fill="#fff"
      />
      <path
        d="M15.055 52.648c0-.712-1.425-1.741-1.425-1.741s-7.758-4.908-10.29-6.571c-2.533-1.662-2.138-2.928-2.138-2.928v-7.362s0 .475.95 1.108c.95.634 11.795 7.758 11.795 7.758s1.108 1.029 1.108 1.662z"
        fill="none"
        stroke="#6d6d6d"
        strokeWidth={0.119}
        strokeMiterlimit={10}
      />
      <path
        d="M15.055 19.782c0-.712-1.425-1.741-1.425-1.741S5.872 13.133 3.34 11.47C.807 9.808 1.202 8.542 1.202 8.542V1.18s0 .475.95 1.108c.95.634 11.795 7.758 11.795 7.758s1.108 1.029 1.108 1.662z"
        fill="#fff"
      />
      <path
        d="M15.055 19.782c0-.712-1.425-1.741-1.425-1.741S5.872 13.133 3.34 11.47C.807 9.808 1.202 8.542 1.202 8.542V1.18s0 .475.95 1.108c.95.634 11.795 7.758 11.795 7.758s1.108 1.029 1.108 1.662z"
        fill="none"
        stroke="#6d6d6d"
        strokeWidth={0.119}
        strokeMiterlimit={10}
      />
      <path
        d="M15.055 36.207c0-.712-1.425-1.742-1.425-1.742s-7.758-4.907-10.29-6.57c-2.533-1.662-2.138-2.928-2.138-2.928v-7.362s0 .475.95 1.108c.95.634 11.795 7.758 11.795 7.758s1.108 1.028 1.108 1.662z"
        fill="#fff"
      />
      <path
        d="M15.055 36.207c0-.712-1.425-1.742-1.425-1.742s-7.758-4.907-10.29-6.57c-2.533-1.662-2.138-2.928-2.138-2.928v-7.362s0 .475.95 1.108c.95.634 11.795 7.758 11.795 7.758s1.108 1.028 1.108 1.662z"
        fill="none"
        stroke="#6d6d6d"
        strokeWidth={0.119}
        strokeMiterlimit={10}
      />
    </g>
  </svg>
);

export default SvgProteinIcon;

