import React from "react";

const SvgPredictorIcon = props => (
  <svg viewBox="0 0 13.229 13.229" height="1em" width="1em" {...props}>
    <defs>
      <clipPath id="PredictorIcon_svg__a" clipPathUnits="userSpaceOnUse">
        <path d="M0 40.146h41.182V0H0z" />
      </clipPath>
      <clipPath id="PredictorIcon_svg__b" clipPathUnits="userSpaceOnUse">
        <path d="M0 40.146h41.182V0H0z" />
      </clipPath>
    </defs>
    <g
      clipPath="url(#PredictorIcon_svg__a)"
      transform="matrix(.29427 0 0 -.29427 .532 12.623)"
    >
      <g clipPath="url(#PredictorIcon_svg__b)" opacity={0.56}>
        <path
          d="M30.82 17.163a5.953 5.953 0 1 0-11.906 0 5.953 5.953 0 0 0 11.907 0"
          fill="#010101"
        />
        <path
          d="M28.656 19.003a1.083 1.083 0 1 0-2.166 0 1.083 1.083 0 0 0 2.166 0"
          fill="#fff"
        />
        <path
          d="M7.385 36.454a3.692 3.692 0 1 0-7.384 0 3.692 3.692 0 0 0 7.384 0"
          fill="#010101"
        />
        <path
          d="M6.042 37.596a.671.671 0 1 0-1.343 0 .671.671 0 0 0 1.343 0"
          fill="#fff"
        />
        <path
          d="M25.694 20.556L37.11 31.972l.758-.757-11.416-11.416zM18.343 4.554l5.426 13.338.991-.402-5.427-13.338zM27.065 13.908l.729.784 9.495-8.816-.73-.785zM3.337 36.053l.71.802 19.668-17.406-.71-.802z"
          fill="#010101"
        />
        <path
          d="M21.497 3.692a3.693 3.693 0 1 0-7.385.002 3.693 3.693 0 0 0 7.385-.002"
          fill="#010101"
        />
        <path
          d="M20.154 4.833a.671.671 0 1 0-1.342 0 .671.671 0 0 0 1.342 0"
          fill="#fff"
        />
        <path
          d="M41.182 31.594a3.692 3.692 0 1 0-7.384 0 3.692 3.692 0 0 0 7.384 0"
          fill="#010101"
        />
        <path
          d="M39.84 32.735a.672.672 0 1 0-1.345 0 .672.672 0 0 0 1.344 0"
          fill="#fff"
        />
        <path
          d="M39.826 5.596a2.788 2.788 0 1 0-5.577 0 2.788 2.788 0 0 0 5.577 0"
          fill="#010101"
        />
        <path
          d="M38.811 6.458a.507.507 0 1 0-1.014 0 .507.507 0 0 0 1.014 0"
          fill="#fff"
        />
        <path
          d="M27.342 31.81a2.788 2.788 0 1 0-5.576.002 2.788 2.788 0 0 0 5.576-.001"
          fill="#010101"
        />
        <path
          d="M26.329 32.673a.508.508 0 1 0-1.016.001.508.508 0 0 0 1.016-.001"
          fill="#fff"
        />
        <path
          d="M17.177 16.474a2.788 2.788 0 1 0-5.575 0 2.788 2.788 0 0 0 5.575 0"
          fill="#010101"
        />
        <path
          d="M16.163 17.336a.507.507 0 1 0-1.014 0 .507.507 0 0 0 1.014 0"
          fill="#fff"
        />
        <path
          d="M24.134 31.586h1.069v-9.63h-1.069zM14.632 16.783l5.707.535.1-1.065-5.706-.535zM29.592 17.673l9.452 1.249.14-1.062-9.452-1.25z"
          fill="#010101"
        />
        <path
          d="M39.886 18.282a1.897 1.897 0 1 0-3.793.002 1.897 1.897 0 0 0 3.793-.002"
          fill="#010101"
        />
        <path
          d="M39.197 18.869a.345.345 0 1 0-.69 0 .345.345 0 0 0 .69 0"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default SvgPredictorIcon;

