import React from "react";

const SvgReadingIcon = props => (
  <svg viewBox="0 0 13.229 13.229" height="1em" width="1em" {...props}>
    <defs>
      <clipPath id="ReadingIcon_svg__a" clipPathUnits="userSpaceOnUse">
        <path d="M0 39.394h39.201V0H0z" />
      </clipPath>
      <clipPath id="ReadingIcon_svg__b" clipPathUnits="userSpaceOnUse">
        <path d="M0 39.394h39.2V0H0z" />
      </clipPath>
    </defs>
    <g
      clipPath="url(#ReadingIcon_svg__a)"
      transform="matrix(.3142 0 0 -.3142 .598 12.738)"
    >
      <g clipPath="url(#ReadingIcon_svg__b)" opacity={0.56}>
        <path
          d="M31.067 14.904a5.466 5.466 0 0 0-5.458 5.458 5.466 5.466 0 0 0 5.458 5.458 5.465 5.465 0 0 0 5.457-5.458 5.465 5.465 0 0 0-5.457-5.458m-22.392 0a5.466 5.466 0 0 0-5.458 5.458 5.467 5.467 0 0 0 5.458 5.458 5.466 5.466 0 0 0 5.46-5.458 5.464 5.464 0 0 0-5.46-5.458m26.866 11.015a.824.824 0 0 1-.026.264h-.002l-3.67 13.21H26.87a.84.84 0 0 1 0-1.679h3.698l2.963-10.661a7.097 7.097 0 0 1-2.463.446 7.137 7.137 0 0 1-7.137-7.137c0-.19.014-.375.03-.56h-8.175c.013.185.028.37.028.56a7.139 7.139 0 0 1-7.138 7.137 7.092 7.092 0 0 1-2.463-.446l2.961 10.662h3.702a.84.84 0 0 1 0 1.678l-4.978.002-3.669-13.211a.89.89 0 0 1-.026-.265 7.12 7.12 0 0 1-2.663-5.557 7.138 7.138 0 0 1 7.136-7.137 7.137 7.137 0 0 1 6.774 4.897h8.845a7.134 7.134 0 0 1 6.773-4.897 7.14 7.14 0 0 1 7.138 7.137 7.125 7.125 0 0 1-2.664 5.557M36.436 4.023H3.918v1.68h32.193a9.297 9.297 0 0 0-.036.805c0 .344.035.68.086 1.014H3.918v1.68H36.64c.385.889.95 1.716 1.668 2.447H3.78a3.778 3.778 0 0 1-3.78-3.777V3.779a3.78 3.78 0 0 1 3.78-3.78h34.08c.473 0 .924.092 1.341.25-1.418 1.012-2.31 2.31-2.764 3.774"
          fill="#010101"
        />
      </g>
    </g>
  </svg>
);

export default SvgReadingIcon;

